import {GearIcon} from '@primer/octicons-react'
import {ActionList, ActionMenu} from '@primer/react'

import {DiffCompactLinesPopover} from './DiffCompactLinesPopover'
import {DiffLinePresentationToggles} from './DiffLinePresentationToggles'
import {DiffViewPreferenceToggle} from './DiffViewPreferenceToggle'

export function DiffViewSettings() {
  return (
    <>
      <ActionMenu>
        <ActionMenu.Anchor>
          <button className="Button Button--iconOnly Button--invisible ml-2" aria-label="Diff view settings">
            <GearIcon />
          </button>
        </ActionMenu.Anchor>
        <ActionMenu.Overlay>
          <ActionList>
            <DiffViewPreferenceToggle />
            <ActionList.Divider />
            <DiffLinePresentationToggles />
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
      <DiffCompactLinesPopover />
    </>
  )
}

try{ DiffViewSettings.displayName ||= 'DiffViewSettings' } catch {}