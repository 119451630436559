import {ActionList} from '@primer/react'

import {useDiffViewSettings} from '../../contexts/DiffViewSettingsContext'

export function DiffLinePresentationToggles() {
  const {hideWhitespace, updateHideWhitespace, lineSpacing, updateLineSpacing} = useDiffViewSettings()

  return (
    <ActionList.Group selectionVariant="multiple" variant="subtle">
      <ActionList.Item selected={hideWhitespace} onSelect={() => updateHideWhitespace(!hideWhitespace)}>
        Hide whitespace
      </ActionList.Item>
      <ActionList.Item
        selected={lineSpacing === 'compact'}
        onSelect={() => {
          if (lineSpacing === 'compact') {
            updateLineSpacing('relaxed')
          } else {
            updateLineSpacing('compact')
          }
        }}
      >
        Compact line height
      </ActionList.Item>
    </ActionList.Group>
  )
}

try{ DiffLinePresentationToggles.displayName ||= 'DiffLinePresentationToggles' } catch {}